/* eslint-disable nordic/i18n-jsx-no-literals */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
const React = require('react');
const PropTypes = require('prop-types');
const TextField = require('@andes/textfield');
const Tooltip = require('@andes/tooltip');
const FormAndes = require('@andes/form');
const Button = require('@andes/button');
const Snackbar = require('@andes/snackbar');
const Dropdown = require('@andes/dropdown');
const Message = require('@andes/message');
const restclient = require('nordic/restclient');
const ReCAPTCHA = require('../recaptcha');

const { DropdownItem } = Dropdown;
const { useState, useEffect } = React;
const Help = require('../assets/help');

const request = restclient({
  timeout: 50000,
  baseURL: '/landing/api',
  retries: 1,
});

const Form = (props) => {
  const {
    data: {
      SSFF,
      countries,
      oid,
      retURL,
      geoIp,
      referral: {
        name: referralName,
        country: defaultCountry
      },
      referralID,
    },
    siteKey,
  } = props;

  const [referral, setReferral] = useState({ name: referralName, id: referralID, country: defaultCountry });
  const [formValues, setFormValues] = useState({
    first_name: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    last_name: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    company: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    email: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    Country_of_Origin__c: {
      value: referral.country,
      isCompleted: !!referral.country,
      modifier: undefined,
      allowReset: !referral.country,
    },
    Provincia_Estado__c: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    city: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    phone: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    url: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    Monthly_Sales_CBT__c: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    Category_CBT__c: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    Tipo_de_candidato__c: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    Logistics__c: {
      value: '',
      isCompleted: false,
      modifier: undefined,
      allowReset: true,
    },
    retURL: {
      value: retURL,
      allowReset: false,
    },
    oid: {
      value: oid,
      allowReset: false,
    },
    Referal__c: {
      value: referral.name,
      allowReset: false,
    },
    Referal_Identity__c: {
      value: referral.id,
      allowReset: false,
    },
    Lead_Tags__c: {
      value: 'CBT',
      allowReset: false,
    },
    recordType: {
      value: '01238000000Lx8x',
      allowReset: false,
    },
    Pa_s__c: {
      value: 'Regional',
      allowReset: false,
    },
    lead_source: {
      value: 'Web',
      allowReset: false,
    },
    Geo_Ip__c: {
      value: geoIp,
      allowReset: false,
    },
    Object_Name__c: {
      value: '',
      allowReset: false,
    },
  });
  const [isLoading, setLoadingButton] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [snackbarType, setSnackbarType] = useState('default');
  const [usStates, setUsStates] = useState('');
  const [stateLoading, setStateLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const regexEmptyString = /\S+/;
  const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const regexUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const regexPhone = /^[\+]?[0-9]{10,15}$/im;

  const validateInputValue = (value, regex) => {
    return regex.test(value);
  };

  const validateInputsFilled = () => {
    let counterValidator = 0;
    const inputsArray = Object.entries(formValues);
    inputsArray.forEach(item => {
      if (item[1].isCompleted) {
        counterValidator += 1;
      }
    });
    return counterValidator !== 11;
  };

  const setInputValues = (key, value, isCompleted, modifier) => {
    setFormValues(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        value,
        isCompleted,
        modifier,
      }
    }));
  };

  const deleteInputsValue = () => {
    const inputsArray = Object.keys(formValues);
    inputsArray.forEach(item => {
      if (formValues[item].allowReset) {
        setInputValues(item, '', false, undefined);
      }
    });
  };

  const formatPhoneNumber = (number) => {
    const digits = number.replace(/\D/g, "");
    return `+${digits}`;
  };

  const handleInputChange = (event, regex) => {
    let { value, name } = event.target;
    if (name === 'phone' && !!value) {
      value = formatPhoneNumber(value);
    }
    const isValid = validateInputValue(value, regex);
    const modifier = isValid ? undefined : 'error';
    setInputValues(name, value, isValid, modifier);
  };

  const valueHasChanged = (currentValue, newValue) => {
    return currentValue !== newValue;
  };

  const handleDropdownChange = (event, value, name) => {
    if (valueHasChanged(formValues[name].value, value)) {
      switch (name) {
        default: {
          setInputValues(name, value, true, undefined);
        }
      }
    }
  };

  const handleIframeLoad = (event) => {
    const { target: { contentWindow } } = event;
    setLoadingButton(true);
    setMessageSnackbar('');

    if (contentWindow) {
      setMessageSnackbar('Thank you for sharing your business information. We will get in touch with you soon!');
      setSnackbarType('success');
      setLoadingButton(false);
      deleteInputsValue();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Subscribe');
      }
    }
    const data = {
      formValues,
      token,
      rendered: loaded
    };
    request.post(`/postForm`, {
      headers: { 'Content-Type': 'application/json' },
      data,
    })
      .then(response => {
        setFormValues(prev => ({
          ...prev,
          Object_Name__c: {
            value: response.data.object_name,
            allowReset: false,
          }
        }));
        form.submit();
      }).catch((err) => {
        setMessageSnackbar(err?.response?.data || 'There was an error, please try again later!');
        setSnackbarType('error');
      });
    setMessageSnackbar("");
  };

  const isCbtLandingReferral = (name) => {
    return name === 'CBT-Landing';
  };

  useEffect(() => {
    if (formValues.Country_of_Origin__c.value === 'US' && !usStates) {
      setStateLoading(true);
      request.get('/location/states/US').then((response) => {
        setUsStates(response.data);
        setStateLoading(false);
      });
    }
  }, [formValues.Country_of_Origin__c, usStates]);

  useEffect(() => {
    const localStorageData = window.localStorage.getItem('referral');
    const currentReferral = localStorageData && JSON.parse(localStorageData);
    if (currentReferral && !isCbtLandingReferral(currentReferral.name)) {
      if (!isCbtLandingReferral(referral.name)) {
        window.localStorage.setItem('referral', JSON.stringify({ name: referral.name, country: referral.country, id: referral.id }));
      } else {
        setReferral({ name: currentReferral.name, country: currentReferral.country, id: currentReferral.id });
        setFormValues(prev => ({
          ...prev,
          Referal__c: {
            value: currentReferral.name,
            allowReset: false,
          },
          Referal_Identity__c: {
            value: currentReferral.id,
            allowReset: false,
          },
          Country_of_Origin__c: {
            value: currentReferral.country,
            isCompleted: !!currentReferral.country,
            modifier: undefined,
            allowReset: !currentReferral.country,
          },
        }));
      }
    } else {
      window.localStorage.setItem('referral', JSON.stringify({ name: referral.name, country: referral.country, id: referral.id }));
    }
  }, []);

  return (
    <div className="form-section">
      <h2 className="form-section__title">
        Ready to start? Fill in your information and we’ll contact you!
      </h2>
      <div className="form-section__form-conteiner">
        <span className="form-conteiner__image" />
        <div className="form-conteiner__form">
          <FormAndes
            srLabel="form-andes"
            action={SSFF}
            method="POST"
            target="hiddenIframe"
            onSubmit={handleSubmit}
          >
            <div className="first-and-last-name__container">
              <TextField
                srLabel="input-first-name"
                label="First Name"
                name="first_name"
                value={formValues.first_name.value}
                onChange={(event) => handleInputChange(event, regexEmptyString)}
                helper={
                  formValues.first_name.modifier === "error"
                  && "Fill in your First Name"
                }
                modifier={formValues.first_name.modifier}
                countdown
                maxLength={30}
                className="first-and-last-name__text"
              />
              <TextField
                srLabel="input-last-name"
                label="Last Name"
                name="last_name"
                value={formValues.last_name.value}
                onChange={(event) => handleInputChange(event, regexEmptyString)}
                helper={
                  formValues.last_name.modifier === "error"
                    ? "Fill in your Last Name"
                    : ""
                }
                modifier={formValues.last_name.modifier}
                countdown
                maxLength={30}
                className="first-and-last-name__text"
              />
            </div>
            <TextField
              srLabel="input-company-name"
              label="Company Name"
              name="company"
              value={formValues.company.value}
              onChange={(event) => handleInputChange(event, regexEmptyString)}
              helper={
                formValues.company.modifier === "error"
                  ? "Fill in your Company Name"
                  : ""
              }
              modifier={formValues.company.modifier}
              countdown
              maxLength={254}
            />
            <TextField
              srLabel="input-corporate-email"
              label="Corporate E-mail"
              name="email"
              value={formValues.email.value}
              onChange={(event) => handleInputChange(event, regexEmail)}
              helper={
                formValues.email.modifier === "error"
                  ? "Enter a valid email address"
                  : ""
              }
              modifier={formValues.email.modifier}
              countdown
              maxLength={100}
            >
              <Tooltip
                srLabel="tooltip-email"
                className="tooltip-email"
                content="This will be your primary account email"
                side="bottom"
              >
                <Help />
              </Tooltip>
            </TextField>
            <Tooltip
              srLabel="tooltip-country"
              className="tooltip-country"
              content="This will be your primary account country"
              side="bottom"
            >
              <Dropdown
                srLabel="dropdown-country"
                label="Country"
                name="Country_of_Origin__c"
                className="form-conteiner__country"
                onChange={(event, value) => {
                  handleDropdownChange(event, value, "Country_of_Origin__c");
                  handleDropdownChange(event, null, "Provincia_Estado__c");
                }}
                modifier={formValues.Country_of_Origin__c.modifier}
                valid={formValues.Country_of_Origin__c.modifier !== "error"}
                placeholder=" "
                type="form"
                disabled={!!defaultCountry}
                defaultValue={defaultCountry}
              >
                {countries.map((country) => (
                  <DropdownItem
                    srLabel={country.name}
                    value={country.id}
                    key={country.id}
                    title={country.name}
                    selected={country.id === defaultCountry}
                  />
                ))}
              </Dropdown>
              <div className="tooltip-help__icon">
                <Help />
              </div>
            </Tooltip>
            {formValues.Country_of_Origin__c.value === "US" && (
              <Dropdown
                srLabel="dropdown-state"
                label="State"
                name="Provincia_Estado__c"
                className="form-conteiner__country"
                onChange={(event, value) => handleDropdownChange(event, value, "Provincia_Estado__c")}
                modifier={formValues.Provincia_Estado__c.modifier}
                valid={formValues.Provincia_Estado__c.modifier !== "error"}
                placeholder=" "
                type="form"
                menuMaxHeight={300}
                disabled={stateLoading}
                search={!!usStates}
                searchPlaceholder="Search"
              >
                {usStates
                  && usStates.map((state) => (
                    <DropdownItem
                      srLabel={state.name}
                      value={state.name}
                      key={state.id}
                      title={state.name}
                    />
                  ))}
              </Dropdown>
            )}
            <TextField
              srLabel="input-phone"
              label="Phone"
              placeholder="+861234567890"
              value={formValues.phone.value}
              name="phone"
              onChange={(event) => handleInputChange(event, regexPhone)}
              helper={
                formValues.phone.modifier === "error"
                  ? "Enter a valid phone number"
                  : ""
              }
              modifier={formValues.phone.modifier}
              countdown
              maxLength={16}
            />
            <TextField
              srLabel="input-business-web"
              label="Your business website or marketplace URL"
              placeholder="http://www.yourbusinessname.com"
              value={formValues.url.value}
              name="url"
              onChange={(event) => handleInputChange(event, regexUrl)}
              helper={
                formValues.url.modifier === "error" ? "Enter a valid URL" : ""
              }
              modifier={formValues.url.modifier}
              countdown
              maxLength={100}
            />
            <Dropdown
              srLabel="dropdown-sales"
              label="Monthly sales (in USD)"
              name="Monthly_Sales_CBT__c"
              onChange={(event, value) => handleDropdownChange(event, value, "Monthly_Sales_CBT__c")}
              modifier={formValues.Monthly_Sales_CBT__c.modifier}
              valid={formValues.Monthly_Sales_CBT__c.modifier !== "error"}
              placeholder=" "
              type="form"
            >
              <DropdownItem
                srLabel="sales-item"
                value="0 -10.000"
                title="0 - 10.000"
                key="0 -10.000"
              />
              <DropdownItem
                srLabel="sales-item"
                value="10.000 -100.000"
                title="10.000 - 100.000"
              />
              <DropdownItem
                srLabel="sales-item"
                value="100.000 - 500.000"
                title="100.000 - 500.000"
                key="100.000 - 500.000"
              />
              <DropdownItem
                srLabel="sales-item"
                value="500.000 - 1.000.000"
                title="500.000 - 1.000.000"
                key="500.000 - 1.000.000"
              />
              <DropdownItem
                srLabel="sales-item"
                value="+1.000.000"
                title="+1.000.000"
                key="+1.000.000"
              />
            </Dropdown>
            <Dropdown
              srLabel="dropdown-category"
              label="Main category you sell in"
              name="Category_CBT__c"
              className="form-conteiner__category"
              onChange={(event, value) => handleDropdownChange(event, value, "Category_CBT__c")}
              modifier={formValues.Category_CBT__c.modifier}
              valid={formValues.Category_CBT__c.modifier !== "error"}
              placeholder=" "
              type="form"
              menuMaxHeight={300}
            >
              <DropdownItem
                srLabel="category-item"
                value="CE - Video game consoles"
                title="Consumer Electronics - Video game consoles"
                key="CE - Video game consoles"
              />
              <DropdownItem
                srLabel="category-item"
                value="CE - Computers and printers"
                title="Consumer Electronics - Computers and printers"
                key="CE - Computers and printers"
              />
              <DropdownItem
                srLabel="category-item"
                value="CE - Mobiles and smartphones"
                title="Consumer Electronics - Mobiles and smartphones"
                key="CE - Mobiles and smartphones"
              />
              <DropdownItem
                srLabel="category-item"
                value="CE - Audio and video"
                title="Consumer Electronics - Audio and video"
                key="CE - Audio and video"
              />
              <DropdownItem
                srLabel="category-item"
                value="CE - Cameras and drones"
                title="Consumer Electronics - Cameras and drones"
                key="CE - Cameras and drones"
              />
              <DropdownItem
                srLabel="category-item"
                value="CE - Others"
                title="Consumer Electronics - Others"
                key="CE - Others"
              />
              <DropdownItem
                srLabel="category-item"
                value="Automobile"
                title="Automobile"
                key="Automobile"
              />
              <DropdownItem
                srLabel="category-item"
                value="Apparel"
                title="Apparel (clothing)"
                key="Apparel"
              />
              <DropdownItem
                srLabel="category-item"
                value="Beauty"
                title="Beauty"
                key="Beauty"
              />
              <DropdownItem
                srLabel="category-item"
                value="Home"
                title="Home"
                key="Home"
              />
              <DropdownItem
                srLabel="category-item"
                value="Tools and construction"
                title="Tools and construction"
                key="Tools and construction"
              />
              <DropdownItem
                srLabel="category-item"
                value="Toys & Babies"
                title="Toys & Babies"
                key="Toys & Babies"
              />
              <DropdownItem
                srLabel="category-item"
                value="CPG"
                title="CPG"
                key="CPG"
              />
              <DropdownItem
                srLabel="category-item"
                value="Supplements"
                title="Supplements"
                key="Supplements"
              />
              <DropdownItem
                srLabel="category-item"
                value="Others"
                title="Others"
                key="Others"
              />
            </Dropdown>
            <Dropdown
              srLabel="dropdown-business"
              label="Business model type"
              name="Tipo_de_candidato__c"
              onChange={(event, value) => handleDropdownChange(event, value, "Tipo_de_candidato__c")}
              modifier={formValues.Tipo_de_candidato__c.modifier}
              valid={formValues.Tipo_de_candidato__c.modifier !== "error"}
              placeholder=" "
              type="form"
              menuMaxHeight={300}
            >
              <DropdownItem
                srLabel="business-item"
                value="Brand Owner"
                title="Brand Owner"
                key="Brand Owner"
              />
              <DropdownItem
                srLabel="business-item"
                value="Reseller"
                title="Reseller"
                key="Reseller"
              />
              <DropdownItem
                srLabel="business-item"
                value="Partner"
                title="Partner"
                key="Partner"
              />
              <DropdownItem
                srLabel="business-item"
                value="Integrator"
                title="Integrator"
                key="Integrator"
              />
            </Dropdown>
            <input
              type="hidden"
              className="form-section__hidden"
              name="Country_of_Origin__c"
              value={formValues.Country_of_Origin__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="US_State__c"
              value={formValues.Provincia_Estado__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="city"
              value={formValues.city.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Monthly_Sales_CBT__c"
              value={formValues.Monthly_Sales_CBT__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Category_CBT__c"
              value={formValues.Category_CBT__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Tipo_de_candidato__c"
              value={formValues.Tipo_de_candidato__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Logistics__c"
              value={formValues.Logistics__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="retURL"
              value={formValues.retURL.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="oid"
              value={formValues.oid.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Referal__c"
              value={formValues.Referal__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Referal_Identity__c"
              value={formValues.Referal_Identity__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Lead_Tags__c"
              value={formValues.Lead_Tags__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="recordType"
              value={formValues.recordType.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Pa_s__c"
              value={formValues.Pa_s__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="lead_source"
              value={formValues.lead_source.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Geo_Ip__c"
              value={formValues.Geo_Ip__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="Object_Name__c"
              value={formValues.Object_Name__c.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="FirstName"
              value={formValues.first_name.value}
            />
            <input
              type="hidden"
              className="form-section__hidden"
              name="LastName"
              value={formValues.last_name.value}
            />
            <ReCAPTCHA
              srLabel="recaptcha"
              siteKey={siteKey}
              setToken={setToken}
              setLoaded={setLoaded}
            />
            <Button
             srLabel="button-submit"
              hierarchy="loud"
              loading={isLoading}
              disabled={validateInputsFilled()}
              type="submit"
              size="small"
            >
              Submit
            </Button>
          </FormAndes>
        </div>
      </div>
      <Snackbar
        message={messageSnackbar}
        type={snackbarType}
        show={!!messageSnackbar}
        delay={3000}
      />
      <iframe
        id="hiddenIframe"
        name="hiddenIframe"
        title="hiddenIframe"
        src="about:blank"
        className="form-section__hidden"
        onLoad={handleIframeLoad}
      />
    </div>
  );
};

Form.propTypes = {
  data: PropTypes.shape({
    SSFF: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.shape({
      currency_id: PropTypes.string,
      id: PropTypes.string,
      locale: PropTypes.string,
      name: PropTypes.string,
    })),
    geoIp: PropTypes.string,
    oid: PropTypes.string,
    referral: PropTypes.shape({
      country: PropTypes.string,
      name: PropTypes.string,
    }),
    referralID: PropTypes.string,
    retURL: PropTypes.string,
  }),
  siteKey: PropTypes.string,
};

module.exports = Form;
