/* eslint-disable react/jsx-indent */
/* eslint-disable nordic/i18n-jsx-no-literals */
/**
 * Module dependencies
 */
const React = require('react');
const Head = require('nordic/head');
const Script = require('nordic/script');
const Page = require('nordic/page');
const Style = require('nordic/style');
const Modal = require('@andes/modal');
const Button = require('@andes/button');
const Form = require('../components/form');

class AboutView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideoModal: false,
    };
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderNumbers = this.renderNumbers.bind(this);
    this.renderGoals = this.renderGoals.bind(this);
  }

  toggleVideoModal() {
    this.setState({
      showVideoModal: !this.state.showVideoModal,
    });
  }

  buildMetricItem(metrics, key) {
    const { hidden, number, text } = metrics[key];

    return (
      <div className="item-data">
        <div className={`rectangle ${key}`} />
        <h3 className="hidden-h3">{hidden}</h3>
        <div className="number">{number}</div>
        <div className="text">{text}</div>
      </div>
    );
  }

  renderNumbers() {
    const { data: { metrics: { values, reference } } } = this.props;
    return (
      <div>
        <div className="numbers-section__content  mobile">
          <div className="numbers-section__row">
            {this.buildMetricItem(values, 'countries')}
            {this.buildMetricItem(values, 'buyers')}
          </div>
          <div className="numbers-section__row">
            {this.buildMetricItem(values, 'sellers')}
            {this.buildMetricItem(values, 'visits')}
          </div>
          <div className="numbers-section__row">
            {this.buildMetricItem(values, 'orders')}
            {this.buildMetricItem(values, 'gmv')}
          </div>
        </div>
        <div className="numbers-section__content desktop">
          <div className="numbers-section__row">
            {this.buildMetricItem(values, 'countries')}
            {this.buildMetricItem(values, 'buyers')}
            {this.buildMetricItem(values, 'sellers')}
          </div>
          <div className="numbers-section__row">
            {this.buildMetricItem(values, 'visits')}
            {this.buildMetricItem(values, 'orders')}
            {this.buildMetricItem(values, 'gmv')}
          </div>
        </div>
        <div className="numbers-section__reference">
          {reference}
        </div>
      </div>

    );
  }

  renderGoals() {
    return (
      <div>
        <div className="goals-section__conteiner-goals mobile">
          <div className="goals-section__item">
            <div>
              <span className="goals-section__image goals-section__image-products" />
            </div>
            <div>
              <div className="goals-section__text-conteiner">
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">Multifunctional Global Selling Platform</h3>
                  <p className="goals-section__item-subtitle">Sell in Mexico, Brazil, Chile and Colombia with a single account.</p>
                </div>

              </div>
            </div>
          </div>
          <div className="goals-section__item">
            <div>
              <span className="goals-section__image goals-section__image-payments" />
            </div>
            <div>
              <div className="goals-section__text-conteiner">
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">International payments</h3>
                  <p className="goals-section__item-subtitle">List and collect your orders in USD in your bank account, while still accepting purchases in local currency.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="goals-section__item">
            <div>
              <span className="goals-section__image goals-section__image-shipping" />
            </div>
            <div>
              <div className="goals-section__text-conteiner">
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">Shipping solutions</h3>
                  <p className="goals-section__item-subtitle">Mercado Libre offers you different options: Mercado Envios Full warehouses and Mercado Envios Direct to consumer shipping networks.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="goals-section__item">
            <div>
              <span className="goals-section__image goals-section__image-ads" />
            </div>
            <div>
              <div className="goals-section__text-conteiner">
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">Mercado Libre Ads</h3>
                  <p className="goals-section__item-subtitle">Boost your sales with our advertising solution by showcasing your products in the top search results.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="goals-section__conteiner-goals desktop">
          <div className="goals-section__item">
            <div className="goals-section__item-left">
              <div className="goals-section__text-conteiner">
                <div className="rectangle-title" />
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">Multifunctional Global Selling Platform</h3>
                  <p className="goals-section__item-subtitle">Sell in Mexico, Brazil, Chile and Colombia with a single account.</p>
                </div>

              </div>
            </div>
            <div className="goals-section__item-right">
              <span className="goals-section__image goals-section__image-products" />
            </div>
          </div>
          <div className="goals-section__item">
            <div className="goals-section__item-left">
              <span className="goals-section__image goals-section__image-payments" />
            </div>
            <div className="goals-section__item-right">
              <div className="goals-section__text-conteiner">
                <div className="rectangle-title" />
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">International payments</h3>
                  <p className="goals-section__item-subtitle">List and collect your orders in USD in your bank account, while still accepting purchases in local currency.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="goals-section__item">
            <div className="goals-section__item-left">
              <div className="goals-section__text-conteiner">
                <div className="rectangle-title" />
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">Shipping solutions</h3>
                  <p className="goals-section__item-subtitle">Mercado Libre offers you different options: Mercado Envios Full warehouses and Mercado Envios Direct to consumer shipping networks.</p>
                </div>
              </div>
            </div>
            <div className="goals-section__item-right">
              <span className="goals-section__image goals-section__image-shipping" />
            </div>
          </div>
          <div className="goals-section__item">
            <div className="goals-section__item-left">
              <span className="goals-section__image goals-section__image-ads" />
            </div>
            <div className="goals-section__item-right">
              <div className="goals-section__text-conteiner">
                <div className="rectangle-title" />
                <div className="goals-section__texts">
                  <h3 className="goals-section__item-title">Mercado Libre Ads</h3>
                  <p className="goals-section__item-subtitle">Boost your sales with our advertising solution by showcasing your products in the top search results.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDesktop(device, formData, siteKey) {
    return (
      <div className="landing_onboarding_view_content">
        <div className="portada">
          <div className="portada__content">
            <h1 className="portada__title">Sell in Latin America with Mercado Libre</h1>
            <p className="portada__subtitle">With more than 20 years of experience, Mercado Libre is the #1 marketplace in Latin America and one of the top 10 most visited e-commerce websites in the world.</p>
            {/* <Button hierarchy="loud" size="large" href="#start-selling-form">Start selling</Button> */}
          </div>
        </div>
        <div className="video">
          <div className="video__content">
            <div className="video__text-content">
              <h2 className="video__title">We simplify selling online</h2>
              <p className="video__subtitle">Our goal is to make e-commerce available to everyone in Latam, with a unique ecosystem and platform built to help you simplify cross-border selling. Watch the video to learn more.</p>
            </div>
            <a className="video__button-video" onClick={this.toggleVideoModal} />
            <Modal
              open={this.state.showVideoModal}
              onClose={this.toggleVideoModal}
              type={this.props.device.type === 'mobile' ? 'light' : 'small'}
              className="video-modal"
            >
              <video autoPlay controls className="desktop-video">
                <source src={this.props.data.video} type="video/mp4" />
              </video>
            </Modal>
          </div>
        </div>
        <div className="numbers-section">
          <h2 className="numbres-section__title">Grow with the leading e-commerce platform in the region</h2>
          {this.renderNumbers()}
        </div>
        <div className="marketplaces-section">
          <div className="flags-section__content">
            <h2 className="flags-section__title">Explore the Marketplaces available for Global Selling</h2>
            <div className="flags-section__flags-conteiner">
              <div className="flags-section__country-info">
                <a href="https://www.mercadolibre.com.mx" target="_blank" rel="noopener noreferrer">
                  <span className="flags-section__flag flags-section__flag-mx" />
                </a>
                <h3 className="flags-section__title-country">Mexico</h3>
                <a href="https://www.mercadolibre.com.mx" target="_blank" rel="noopener noreferrer">mercadolibre.com.mx</a>
              </div>
              <div className="flags-section__country-info">
                <a href="https://www.mercadolivre.com.br" target="_blank" rel="noopener noreferrer">
                  <span className="flags-section__flag flags-section__flag-br" />
                </a>
                <h3 className="flags-section__title-country">Brazil</h3>
                <a href="https://www.mercadolivre.com.br" target="_blank" rel="noopener noreferrer">mercadolivre.com.br</a>
              </div>
              <div className="flags-section__country-info">
                <a href="https://www.mercadolibre.cl" target="_blank" rel="noopener noreferrer">
                  <span className="flags-section__flag flags-section__flag-cl" />
                </a>
                <h3 className="flags-section__title-country">Chile</h3>
                <a href="https://www.mercadolibre.cl" target="_blank" rel="noopener noreferrer">mercadolibre.cl</a>
              </div>
              <div className="flags-section__country-info">
                <a href="https://www.mercadolibre.com.co" target="_blank" rel="noopener noreferrer">
                  <span className="flags-section__flag flags-section__flag-co" />
                </a>
                <h3 className="flags-section__title-country">Colombia</h3>
                <a href="https://www.mercadolibre.com.co" target="_blank" rel="noopener noreferrer">mercadolibre.com.co</a>
              </div>
            </div>
          </div>
        </div>
        <div className="goals-section">
          <h2 className="goals-section__title">Mercado Libre Global Selling, a 360° solution for your business </h2>
          {this.renderGoals()}
        </div>
        {formData && (
          <section id="start-selling-form">
            <Form device={device} data={formData} siteKey={siteKey} />
          </section>
        )}
      </div>
    );
  }

  render() {
    const { device, data: { form }, siteKey } = this.props;

    return (
      <Page name="about_landing" state={this.props} styles={false}>
        <Head>
          <meta name="title" content="Mercado Libre Global Selling | Sell in Latin America" />
          <meta name="description" content="Expand your business in the Latin market with Mercado Libre. Discover Global Selling, a platform in which you can easily manage your business." />
          <title>Mercado Libre Global Selling | Sell in Latin America</title>
        </Head>
        <Style
          href="about_landing.css"
          inline
        />
        {this.renderDesktop(device, form, siteKey)}
        <Script src="vendor.js" />
      </Page>
    );
  }
}

/**
 * Inject i18n context as into InboundsView.
 */
module.exports = AboutView;
