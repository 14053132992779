/* eslint-disable react/no-unknown-property */
const React = require('react');

const Help = ({ type }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true">
    <g fill={type === 'MP' ? '#009ee3' : '#3483fa'} fillRule="evenodd">
      <path d="M8.667 9.885H7.422a27.453 27.453 0 0
        1-.005-.327c0-.403.067-.736.2-.996.134-.26.4-.553.801-.879.4-.325.64-.539.718-.64a.855.855
        0 0 0 .18-.527.889.889 0 0 0-.32-.686c-.213-.19-.5-.286-.861-.286-.349
        0-.64.1-.874.298-.235.199-.396.502-.484.909l-1.26-.157c.036-.582.285-1.077.745-1.484.46-.407
        1.065-.61 1.814-.61.788 0 1.415.205 1.88.617.466.412.698.891.698
        1.438 0 .303-.085.59-.256.86-.171.27-.536.638-1.096
        1.103-.29.241-.47.435-.54.581-.07.147-.102.409-.095.786zm-1.245 1.846v-1.372h1.372v1.372H7.422z"
      />
      <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" />
    </g>
  </svg>
);

module.exports = Help;
