const React = require('react');

const { useEffect } = React;


const ReCAPTCHA = ({ siteKey, setToken, setLoaded }) => {
  const onChange = (token) => {
    setToken(token);
  };

  const handleRecaptchaExpired = () => {
    setToken(null);
  };

  const loadedCallback = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.onChange = onChange;
    window.handleRecaptchaExpired = handleRecaptchaExpired;
    window.loadedCallback = loadedCallback;
    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = 'https://www.recaptcha.net/recaptcha/api.js?onload=loadedCallback&hl=en';
    document.head.appendChild(recaptchaScript);
  }, []);

  return (
    <div
      id="g-recaptcha"
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-callback="onChange"
      data-expired-callback="handleRecaptchaExpired"
    />
  );
};

module.exports = ReCAPTCHA;